<template>
	<v-container class="pt-0">
		<div>
			<v-row>
				<v-col cols="12" lg="12" md="12" class="pr-0">
					<s-crud
						:config="config"
						:filter="filter"
						add
						@save="save($event)"
						edit
						remove
						search-input
						title="Config. Grupo Zonas"
						no-full
						height="auto"
					>
						<template v-slot:filter>
							<v-container>
								<v-row
									style="margin-left: 5px"
									justify="center"
								>
									<v-col cols="6" lg="3">
										<s-select-definition
											clearable
											label="Zona Ubicacion"
											:def="1253"
											v-model="filter.TypeZon"
										></s-select-definition>
									</v-col>
								</v-row>
							</v-container>
						</template>

						<template scope="props">
							<v-container>
								<v-card style="margin-bottom: 5px;" flat>
									<v-col>
										<v-row justify="center" class="pt-1">
											<v-col
												cols="12"
												lg="3"
												md="4"
												sm="12"
												class="pt-0"
											>
												<s-select-generic
													label="Zona"
													itemtext="ZonName"
													itemvalue="ZonID"
													id="ZonName"
													full
													autocomplete
													clearable
													:config="configZon"
													v-model="props.item.ZonID"
												>
												</s-select-generic>
											</v-col>
											<v-col
												cols="12"
												lg="3"
												md="4"
												sm="12"
												class="pt-0"
											>
												<s-select-definition
													label="Zona Ubicacion"
													:def="1253"
													v-model="props.item.TypeZon"
												></s-select-definition>
											</v-col>
										</v-row>
									</v-col>
								</v-card>
							</v-container>
						</template>
						<template v-slot:SecStatus="{ row }">
							<v-chip
								:color="
									row.SecStatus == 1 ? 'success' : 'error'
								"
								x-small
							>
								{{ row.SecStatus == 1 ? "Si" : "No" }}
							</v-chip>
						</template>
					</s-crud>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>
<script>
	//Service
	import _sGroupZonService from "../../../services/Technicalassistance/TasGroupZonService";
	import _sTransportPriceService from "../../../services/Technicalassistance/TransportPriceService";

	export default {
		components: {},

		data: () => ({
			filter: {},
			config: {
				model: {
					GznID: "ID",
					SecStatus: ""
				},
				service: _sGroupZonService,
				headers: [
					{
						text: "ID",
						value: "GznID",
						sortable: false,
						width: "20",
						align: "center"
					},
					{ text: "Grupo", value: "TypeZonName", sortable: false },
					{
						text: "Zona",
						value: "ZonName",
						sortable: false,
						align: "center"
					},
					{ text: "Estado", value: "SecStatus", sortable: false, align: "center", width: "100",},
					
				]
			}
		}),

		methods: {
			save(val) {

				if(val.ZonID == null || val.ZonID == 0){
					this.$fun.alert("Seleccione una Zona", "warning")
					return
				}

				if(val.TypeZon == null){
					this.$fun.alert("Seleccione Tipo Zona", "warning")
					return
				}
				
				val.SecStatus = 1
				val.UsrCreateID = this.$fun.getUserID()

				console.log("Guardar", val);
				val.save()
			}
		},

		created () {
			this.configZon = {
			url: _sTransportPriceService.GetZon(),
			title: this.label,
			params: { search: "", requestID: this.$fun.getUserID() },
			};
		}
	};
</script>
